import {getComparator, stableSort} from './tableHelpers';

/**
 * @typedef {Object} SearchValue
 * @property {Array} additionalFilters
 * @property {Array} filters
 * @property {Array} boolFilters
 */

/**
 * Group Object type definition
 * @typedef {Object} Group
 * @property {String} orderBy
 * @property {String} groupTitle
 * @property {String} order
 * @property {Number} rowsPerPage
 * @property {SearchValue} searchValue
 * @property {Array} dataTypes
 * @property {Array} data
 * @property {String} filteredData
 * @property {boolean} showBearBullFilter
 * @property {Number} page
 * @property {null | string} activeFilter
 * @property {String} group
 * @property {Array} orderTypes
 */
const defaultGroupObj = {
  orderBy: '',
  groupTitle: '',
  order: 'asc',
  rowsPerPage: 25,
  searchValue: {
    additionalFilters: [],
    filters: [],
    boolFilters: [],
  },
  dataTypes: [],
  data: [],
  filteredData: [],
  showBearBullFilter: false,
  page: 0,
  activeFilter: null,
  group: '',
  orderTypes: [],
  type: '',
  selectedDate: null,
  showDateSelector: false,
  limit: null,
  isCurrentDate: true,
};

/**
 * Formats the data type to be used in table header.
 * @param {Object} dataType - Object containing all data types and their formats.
 * @param {*} dt - Data type to be formatted.
 * @returns {Object} - Data type property with properties for MUI TableHead.
 */
export const formatDataTypes = (dataType, dt) => {
  if (dt.key === 'Symbol') {
    return {...dt, disablePadding: true, numeric: false};
  }

  if (dataType?.display === 'percentage' && dt.key === 'HO_Chg') {
    return {
      ...dt,
      disablePadding: false,
      numeric: dt.type === 'number',
      key: 'HO_Chg%',
      title: 'HO. Change (%)',
    };
  }
  if (dt.display === 'percentage' && dt.key !== 'HO_Chg') {
    return {
      ...dt,
      disablePadding: false,
      numeric: dt.type === 'number',
    };
  }
  if (dt.key === 'HOS_Label') {
    return {
      ...dt,
      display: 'bullish-bearish',
    };
  }
  return {
    ...dt,
    disablePadding: false,
    numeric: dt.type === 'number',
  };
};

/**
 * Formats the filters based on filter type
 * @param {Object} filt - filter object from websocket
 * @returns {Object} - formatted filter
 */
export const formatFilterableTypes = (filt) => {
  if (filt?.filterable?.type === 'range') {
    return {...filt, value: {min: 0, max: 0}};
  }
  if (filt?.type === 'boolean') {
    const filtClone = {
      ...filt,
      filterable: {
        ...filt.filterable,
        label: filt.title,
        option: false,
      },
    };
    return {...filtClone, value: false};
  }
  if (filt?.type === 'string' && filt?.filterable?.type !== 'hardcode') {
    return {...filt, value: ''};
  }
  if (filt?.type === 'array') {
    return {...filt, value: []};
  }
  return filt;
};

/**
 * Adds the label name to the filter object to display in the UI
 * @param {Object} item - item to be formatted
 * @param {Object} dataType - data type to be formatted
 * @returns {Object} - formatted item
 */
export const formatBoolFilters = (item, dataType) => {
  const fieldName = item.fields.map((field) => {
    const labelName = dataType?.find((dt) => dt.key === field);
    return {
      field,
      option: false,
      label: labelName.title,
    };
  });
  return {...item, fields: fieldName};
};

/**
 * Structures the search value for group
 * @param {Group} groupObj
 * @param {Array} filtersWithValue
 * @param {Array} withFieldLabel
 * @returns {SearchValue} - SearchValue based on group settings
 */
export const createSearchValueObj = (groupObj, filtersWithValue, withFieldLabel) => {
  const allFilterableItems = [...filtersWithValue, ...withFieldLabel];
  const checkboxFilts = allFilterableItems
    .filter((item) => item?.type === 'boolean')
    .map((field) => ({...field, ...field.filterable}));
  return {
    ...groupObj.searchValue,
    filters: allFilterableItems.filter((item) => item?.type !== 'boolean'),
    boolFilters: [
      {
        type: 'boolean',
        fields: checkboxFilts,
      },
    ],
  };
};

/**
 * Takes date and returns string with separated time value
 *
 * @param {date} t - date to format
 * @param {Array} a - Array with format of returned string
 * @param {String} s - separator (default: '-')
 * @returns {String} - formatted date (default: 'YYYY-MM-DD')
 */
export const join = (t, a, s) => {
  const format = (m) => {
    const f = new Intl.DateTimeFormat('en', m);
    return f.format(t);
  };
  const separator = s || '-';
  return a.map(format).join(separator);
};

/**
 * Take URL params, format into a list to join to websocket
 *
 * @returns {Array} - Array of objects with the following structure:
 * @property {String} group - Group name
 * @property {String} type - Type of group (stateview, tickview)
 * @property {String} date - Requested date
 * @property {boolean} joined - Whether the group is joined or not (false by default)
 */
export const setupGroups = (id = null, scannersData = null) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const groupId = id ?? params.id;
  let group = params.id;
  let paramType = params.type;
  let dateSelector;

  if (scannersData) {
    const scanner = scannersData.find((s) => s.slug === groupId);
    if (scanner && scanner.groups) {
      group = scanner.groups.map((g) => g.group).join(',');
      paramType = scanner.groups.map((g) => g.type).join(',');
      dateSelector = scanner.dateSelector;
    }
  }

  const {date: dateParam, showDate} = params;
  const getScannerDiv = document.getElementById('rb_scanner');

  const fromDataAttribute = getScannerDiv?.getAttribute('data-scanner-group') ?? null;

  const fromDataScannerType = getScannerDiv?.getAttribute('data-scanner-type') ?? null;

  const fromDataScannerDate = getScannerDiv?.getAttribute('data-scanner-date') ?? null;

  const showDateSelector = getScannerDiv?.getAttribute('data-scanner-show-date-selector') ?? null;

  const rbGroup = fromDataAttribute ?? group ?? null;
  const rbType = fromDataScannerType ?? paramType ?? null;
  const rbDate = fromDataScannerDate ?? dateParam ?? null;

  const groupArr = rbGroup?.split(',');
  const groupToArr = Array.isArray(groupArr) ? groupArr : groupArr ? [groupArr] : [];

  const typeArr = rbType?.split(',');
  const typeToArr = Array.isArray(typeArr) ? typeArr : typeArr ? [typeArr] : [];

  const a = [{year: 'numeric'}, {month: 'numeric'}, {day: 'numeric'}];
  const today = join(new Date(), a, '-');

  const groupWithType =
    groupToArr?.map((gr, i) => ({
      group: gr,
      type: typeToArr[i] ?? 'tickalert',
      date: rbDate ?? today,
      showDateSelector: showDateSelector ?? showDate ?? dateSelector ?? false,
      joined: false,
      sentJoin: false,
      isCurrentDate: rbDate ? rbDate === today : true,
    })) ?? [];

  return groupWithType;
};

export const getTimeKey = (groupObj) => {
  const keys = Object.keys(groupObj);
  return keys.find((key) => groupObj[key].includes('AM') || groupObj[key].includes('PM'));
};

/**
 * Find timestamp and a number value of row in the table.
 * @param {Group} obj
 * @param {Array} dataTypes
 * @returns {String | Number | null} - timestamp of row or null if not found
 */
export const getObjectKeyValueTime = (obj, dataTypes) => {
  const timeValue = dataTypes.find((dt) => dt?.convertTo?.includes('time')) ?? getTimeKey(obj);
  const numberValue =
    dataTypes.find((dt) => dt?.type?.includes('number') && dt?.key?.includes('Count')) ??
    dataTypes.find((dt) => dt?.type?.includes('number'));
  if (timeValue && numberValue && numberValue?.key && obj[numberValue?.key]?.value) {
    return `${obj[timeValue?.key ?? timeValue]?.value}-${obj[numberValue.key]?.value}`.split(' ').join('');
  }
  if (timeValue && !numberValue) {
    return obj[timeValue?.key ?? timeValue]?.value;
  }
  return null;
};

/**
 * Parses numbers and boolean columns to be displayed in the table.
 * @param {Array} data - The data to be sorted and formatted
 * @param {Array} order - The order to be sorted by
 * @param {String} type - Group type (tickalert, stateview)
 * @returns {Object} - The sorted and formatted data with Symbol as key
 */
export const formatDataForTable = (data, order, type = 'tickalert', isUpdate = false) =>
  data
    .filter((item) => item?.Symbol?.value?.length)
    .map((item) => {
      const itemKeys = Object.keys(item);
      let newItem = item;
      const sort = order ?? [];
      itemKeys.forEach((key) => {
        if (sort.find((dataType) => dataType.key === key)) {
          if (sort.type === 'number') {
            newItem[key] = parseFloat(item[key].value);
          }
        }
      });
      newItem = {
        ...item,
        i60minSqz: item?.i60minSqz?.value ?? false,
        DailySqz: item?.DailySqz?.value ?? false,
        // eslint-disable-next-line camelcase
        HOS_Label: item?.HOS_Label?.value ?? null,
        update: isUpdate ? true : item?.update ?? false,
        keyId:
          type === 'tickalert' ? `${item.Symbol?.value}-${getObjectKeyValueTime(item, order)}` : item.Symbol?.value,
      };
      return newItem;
    })
    .reduce(
      (ac, a) => ({
        ...ac,
        [type === 'tickalert' ? `${a.Symbol?.value}-${getObjectKeyValueTime(a, order)}` : a.Symbol?.value]: a,
      }),
      {},
    );

export const isValidTick = (tick, currentGrp, allActiveFilters) => {
  if (!currentGrp?.activeFilter && !allActiveFilters?.length) return true;

  let meetsCriteria = true;
  if ((currentGrp.activeFilter && meetsCriteria) || currentGrp.activeFilter) {
    // eslint-disable-next-line camelcase
    const bearishBullish = tick?.HOS_Label?.value ?? tick?.HOS_Label;
    meetsCriteria = bearishBullish?.toLowerCase() === currentGrp.activeFilter?.toLowerCase();
  }

  if ((allActiveFilters?.length && meetsCriteria) || (!currentGrp.activeFilter && allActiveFilters?.length)) {
    meetsCriteria = allActiveFilters.every((item) => {
      const {filterable, value, type: itemDataType, key} = item;
      const {type: filterType} = filterable ?? {type: null};

      const {value: tickValue} = tick[key] ?? {value: null};
      const tValue = tickValue ?? tick[key];
      if (filterType === 'range' && itemDataType === 'number') {
        const minToNum = value?.min ? parseFloat(value?.min) : null;
        const maxToNum = value?.max ? parseFloat(value?.max) : null;

        if (minToNum && maxToNum) {
          return tValue >= minToNum && tValue <= maxToNum;
        }

        if (!minToNum && maxToNum) {
          return tValue <= maxToNum;
        }

        if (minToNum && !maxToNum) {
          return tValue >= minToNum;
        }
      }
      if (filterType === 'search' && itemDataType === 'string') {
        return tValue?.toLowerCase().includes(value?.toLowerCase());
      }

      if (filterType === 'boolean') {
        return tValue === value;
      }

      if (itemDataType === 'array') {
        return value.every((tag) => tValue.includes(tag));
      }
      return true;
    });
  }
  return meetsCriteria;
};

/**
 * Update filteredData value based on filters
 * @param {Group} group
 * @returns {Array} - group.filteredData
 */
export const updateFilteredData = (group) => {
  if (!group) return [];
  const currentGrp = group;
  const currentData = currentGrp?.data;
  const searchV = currentGrp?.searchValue;

  if (!currentData || !Object.keys(currentData || {})?.length) {
    currentGrp.filteredData = currentData;
    return currentGrp.filteredData;
  }

  // Filters
  const activeFilts = searchV?.filters?.filter((item) => {
    const {filterable, value, type} = item;
    if (filterable?.type === 'range') {
      return value?.min || value?.max;
    }
    if (filterable?.type === 'search' && type === 'string') {
      return value !== '';
    }
    if (type === 'array') {
      return value?.length;
    }
    return false;
  });

  const activeBooleanFilts = currentGrp?.searchValue?.boolFilters[0]?.fields?.filter((item) => item?.value) || [];

  const allActiveFilters = [...(activeFilts || []), ...activeBooleanFilts];

  if (!allActiveFilters?.length && !currentGrp?.activeFilter) {
    currentGrp.filteredData = {...(currentData || {})};
    return currentGrp.filteredData;
  }

  const filterData = {...currentData};
  const keys = Object.keys(filterData);
  const dataToArray = keys.reduce((acc, key) => [...(acc || []), filterData[key]], []);
  const formattedData = dataToArray.filter((tick) => isValidTick(tick, currentGrp, allActiveFilters));

  const currentSort = stableSort(
    formattedData,
    getComparator(currentGrp.order, currentGrp.orderBy, currentGrp.dataTypes),
  );

  // Save data as Object instead of Array.
  const withHashKey = currentSort.reduce((ac, a) => {
    const key =
      currentGrp.type === 'tickalert'
        ? `${a.Symbol?.value}-${getObjectKeyValueTime(a, currentGrp.dataTypes)}`
        : a.Symbol?.value;
    return {...ac, [key]: a};
  }, {});
  currentGrp.filteredData = withHashKey;
  return currentGrp.filteredData;
};

/**
 * Updates the group object with the new search/filter values
 * @param {String | number | boolean} updatedValue
 * @param {String} key
 * @param {String | null} minMax
 * @param {Group} group
 * @returns {Group} Group with updated value
 */
export const updateFilters = (updatedValue, key, minMax, group) => {
  if (!group) return {};
  const groupToUpdate = {...group};
  groupToUpdate.page = 0;
  const prevSearchValue = groupToUpdate.searchValue;
  const newSearchValueCopy = {...prevSearchValue};
  const filterToUpdate = newSearchValueCopy.filters.find((filter) => filter.key === key);
  const isBoolFilter = newSearchValueCopy?.boolFilters[0]?.fields.find((filter) => filter.key === key);
  if (filterToUpdate) {
    if (minMax) {
      if (minMax === 'min') {
        filterToUpdate.value = {
          ...filterToUpdate.value,
          min: updatedValue,
        };
      }
      if (minMax === 'max') {
        filterToUpdate.value = {
          ...filterToUpdate.value,
          max: updatedValue,
        };
      }
    } else {
      filterToUpdate.value = updatedValue;
    }
  } else if (isBoolFilter) {
    isBoolFilter.value = updatedValue;
    isBoolFilter.option = updatedValue;
  }

  groupToUpdate.searchValue = newSearchValueCopy;
  groupToUpdate.filteredData = updateFilteredData(groupToUpdate);
  return groupToUpdate;
};

/**
 * Setup group object and format to work with MUI Table
 *
 * @param {Group} gr - group object
 * @param {String} group - group name
 * @param {Object} settings - scanner settings from websocket
 * @param {Array} data - scanner data
 * @param {Array} reset - if true, reset group data
 * @returns Group - group object
 */
export const setupSettings = (gr, group, settings, data, type, reset, datePicker, showDateSelector) => {
  // If group object exists in the collection, update it else create new one.
  const groupObj = gr ? (reset ? {...defaultGroupObj, group} : gr) : {...defaultGroupObj};
  if (settings) {
    const {groupInfo, dataType} = settings;
    const {additionalFilters, defaultSort, defaultSortOrder, title, limit, order: itemOrder} = groupInfo;

    const acc = [{year: 'numeric'}, {month: 'numeric'}, {day: 'numeric'}];
    const today = join(new Date(), acc, '-');

    groupObj.group = group;
    groupObj.type = type;
    groupObj.selectedDate = datePicker;
    // set group order
    groupObj.orderTypes = itemOrder;

    // Set default sorting column
    groupObj.orderBy = defaultSort ?? [];

    groupObj.order = defaultSortOrder ?? 'asc';

    // Set Limit
    groupObj.limit = limit ?? false;

    groupObj.isCurrentDate = showDateSelector && datePicker ? datePicker === today : true;

    // Set page title
    groupObj.groupTitle = title ?? group?.split(/(?=[A-Z])/).join(' ');

    const orderedDataTypes =
      dataType && dataType?.length ? [...dataType].sort((a, b) => itemOrder[a.key] - itemOrder[b.key]) : [];

    // Format dataTypes with materialUI settings
    groupObj.dataTypes =
      orderedDataTypes && orderedDataTypes?.length ? orderedDataTypes?.map((dt) => formatDataTypes(dataType, dt)) : [];
    // Format table data with limit.
    if (limit && data) {
      groupObj.data = formatDataForTable(
        data.length > limit ? [...data].reverse().slice(0, limit).reverse() : data,
        orderedDataTypes,
        type,
      );
    } else {
      groupObj.data = data ? formatDataForTable(data, orderedDataTypes, type) : {};
    }

    // Format dataTypes that are filterable for searchValue.
    const filtersWithValue =
      dataType && dataType?.length
        ? dataType
            ?.filter((dt) => Object.keys(dt || {}).includes('filterable'))
            ?.map((filt) => {
              if (filt?.filterable?.type === 'hardcode' && filt?.display === 'bullish-bearish') {
                groupObj.showBearBullFilter = true;
              }
              return formatFilterableTypes(filt);
            })
        : [];

    // Add additional filters for searchValue.
    const withFieldLabel =
      additionalFilters
        ?.filter((item) => item?.type === 'joined-boolean')
        ?.map((item) => formatBoolFilters(item, dataType)) ?? [];

    // Set search value
    groupObj.searchValue = createSearchValueObj(groupObj, filtersWithValue, withFieldLabel);
  }
  groupObj.group = group;
  groupObj.filteredData = updateFilteredData(groupObj, type);
  groupObj.showDateSelector = showDateSelector;
  return groupObj;
};

/**
 * Resets group object to default values
 * @param {Group} gr - Group to reset
 * @returns Group - Group reset to default object.
 */
export const resetSettings = (gr) => ({...defaultGroupObj, group: gr});

/**
 * For stateview tickers, add a timestamp to the key
 * @param {Array} formattedData
 * @param {Array} dataTypes
 * @returns
 */
export const addHashKey = (formattedData, dataTypes) =>
  formattedData.reduce(
    (ac, a) => ({
      ...ac,
      [`${a.Symbol?.value}-${getObjectKeyValueTime(a, dataTypes)}`]: a,
    }),
    {},
  );
