import React from 'react';

const AccessDenied = () => (
  window.location.replace('https://app.ragingbull.com/scanners')
  // <div id="page">
  //   <h1>Opps!!!</h1>
  //   <p>We are unable to start your user session. You can try refresh the page again to see in it works, or access from the <a href="https://app.ragingbull.com/scanners" rel="noreferrer" target="_blank">RagingBull portal</a>.</p>
  // </div>
);

export default AccessDenied;
