import React, { useEffect, useState } from 'react';
import { useOutletContext, useParams } from "react-router-dom";
import {SplitPane} from 'react-collapse-pane';
import Scanner from '../Components/Scanner/Scanner';
import ScannerHeader from '../Components/Header/ScannerHeader';
import {setupGroups, join} from '../Helpers/formatData';
import AdModal from '../Components/Modal/AdModal';

const {REACT_APP_STANDALONE} = process.env;

const Scanners = ({theme, setTheme}) => {
  const [scannersData, userData, groupCollection, groups, isConnected, loadingGroups, wsSocket, dispatch] = useOutletContext();

  const params = useParams();
  const [modalShow, setModalShow] = useState(false);
  const handleClose = () => setModalShow(false);


  const { id: scannerId } = params;
  useEffect(() => {
    if (isConnected && wsSocket) {
      console.log(`Firing scanner load event for ${scannerId}`)
      const scannerGroups = setupGroups(scannerId, scannersData);
      
      // Leave joined groups if any
      groups?.map((group) => {
        if (group.joined) {
          console.log('leaving ', group.group);
          wsSocket?.emit('group:leave', {group: `${decodeURIComponent(group.group)}`});
        }
      });
      dispatch({type: 'CLEAR_GROUP_COLLECTION'});

      // Join groups if matched
      if (scannerGroups?.length) {

        const acc = [{year: 'numeric'}, {month: 'numeric'}, {day: 'numeric'}];
        const today = join(new Date(), acc, '-');
        console.log('Attempting to join groups...');
        const joinedGroups = scannerGroups?.map((group) => {
          if (!group.sentJoin) {
            const {group: rbGroup, type: rbType, date} = group;
            const scannerType = rbType ? decodeURIComponent(rbType) : 'tickalert';

            const groupToJoin = {
              group: `${decodeURIComponent(rbGroup)}`,
            };

            if (date) {
              groupToJoin[date] = date ?? today;
            }
            console.log('joining ', rbGroup);
            wsSocket?.emit(`${scannerType}:join`, groupToJoin);
            return {...group, sentJoin: true};
          }
          console.log('already joined ', group.group);
          return group;
        });
        dispatch({type: 'EMIT_JOIN', payload: {joinedGroups}});

        // Show popup modal 5 seconds after connected
        // setTimeout(() => {
        //   setModalShow(true);
        // }, 5000);

        
        // run adzerk script after scanner loaded
        // window.runAdzerk();
      }

    }
  }, [scannersData?.length, scannerId]);

  const scannerData = scannersData?.find(s => s.slug === scannerId);
  const scannerTitle = scannerData?.title;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const searchParams = Object.fromEntries(urlSearchParams.entries());
  const sideBySide = scannerData ? scannerData.sideBySide : false;
  const showSideBySide =
    document.getElementById('rb_scanner').getAttribute('data-side-by-side') ??
    sideBySide ??
    false;

  const reformatGroups =
    Object.keys(groupCollection || {}).length === 3
      ? groups.map((gr, index) => {
          if (index === 1) {
            return {
              group: groupCollection[gr?.group],
              child: groupCollection[groups[index + 1]?.group] ?? null,
            };
          }
          if (index !== 2) {
            return {group: groupCollection[gr?.group]};
          }
          return null;
        })
      : null;

  const scanners = Object.keys(groupCollection || {}).length
    ? reformatGroups?.length && !showSideBySide
      ? reformatGroups
          .filter((item) => item !== null)
          .map((group) => {
            if (group?.child) {
              return (
                <SplitPane
                  key={groupCollection[group?.group] ?? null}
                  split="horizontal"
                  collapse
                  minSize={0}
                  defaultSize={0}
                >
                  <div
                    className={`${
                      Object.keys(groupCollection || {}).length > 1
                        ? 'multi-screen-scanner scanner-group'
                        : ' scanner-group'
                    }`}
                    key={groupCollection[group?.group?.group] ?? null}
                  >
                    <Scanner
                      group={groupCollection[group?.group?.group] ?? null}
                      dispatch={dispatch}
                      multiScreen
                      hasChild
                      theme={theme}
                      setTheme={setTheme}
                    />
                  </div>
                  <div
                    className={`${
                      Object.keys(groupCollection || {}).length > 1
                        ? 'multi-screen-scanner scanner-group'
                        : ' scanner-group'
                    }`}
                    key={groupCollection[group?.child] ?? null}
                  >
                    <Scanner
                      group={groupCollection[group?.child?.group] ?? null}
                      dispatch={dispatch}
                      multiScreen
                      hasChild
                      theme={theme}
                      setTheme={setTheme}
                    />
                  </div>
                </SplitPane>
              );
            }
            return (
              <div
                className={`${
                  Object.keys(groupCollection || {}).length > 1
                    ? 'multi-screen-scanner scanner-group'
                    : ' scanner-group'
                }`}
                key={groupCollection[group?.group?.group] ?? null}
              >
                <Scanner
                  group={groupCollection[group?.group?.group] ?? null}
                  dispatch={dispatch}
                  multiScreen
                />
                <div className="clearfix" />
              </div>
            );
          })
      : groups?.map((group) => (
          <div
            className={`${
              Object.keys(groupCollection || {}).length > 1
                ? 'multi-screen-scanner scanner-group'
                : ' scanner-group'
            }`}
            key={group}
          >
            <Scanner
              group={groupCollection[group.group] ?? null}
              dispatch={dispatch}
              multiScreen={Object.keys(groupCollection || {}).length > 1}
              theme={theme}
              setTheme={setTheme}
            />
            <div className="clearfix" />
          </div>
        ))
    : null;

  if (
    groupCollection &&
    Object.keys(groupCollection || {})?.length &&
    scanners &&
    scanners.length > 1 &&
    !loadingGroups
  ) {
    return (
      <>      
      <AdModal open={modalShow} handleClose={handleClose} freeUser={userData?.free_access} />
      <ScannerHeader name={scannerTitle} theme={theme} setTheme={setTheme} userData={userData}/>
      <SplitPane split="vertical" collapse minSize={0} defaultSize={0}>
        {scanners}
      </SplitPane>
      </>
    );
  }
  if (
    groupCollection &&
    Object.keys(groupCollection || {})?.length === 1 &&
    !loadingGroups &&
    scanners &&
    scanners.length === 1
  ) {
    return (
      <>
        <AdModal open={modalShow} handleClose={handleClose} freeUser={userData?.free_access} />
        <ScannerHeader name={scannerTitle} theme={theme} setTheme={setTheme} userData={userData}/>
        {scanners}
      </>
    );
  }

  if (!groups || !groups?.length) {
    return (
      <div className="flex items-center justify-center py-8">
        <h1 className="text-3xl">No Scanners Available</h1>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center py-8">
      <h1 className="text-3xl">Loading Scanner</h1>
    </div>
  );
};

export default Scanners;
