import React from 'react';

const NotFound = () => (
  <div id="page">
    <h1>Page Not Found</h1>
    <p>Page request is invalid. To start a session, please access from the <a href="https://app.ragingbull.com/scanners" rel="noreferrer" target="_blank">RagingBull portal</a>.</p>
  </div>
);

export default NotFound;
