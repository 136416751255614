import React from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFilter} from '@fortawesome/free-solid-svg-icons';
import Button from './Button';

const BearishBullishButtons = ({activeFilter, group, dispatch}) => (
  <ButtonGroup
    variant="outlined"
    aria-label="outlined button group"
    className="flex gap-x-1 gap-y-2 items-center filter-btns flex-wrap"
  >
    <Button
      className={`bearish ${
        activeFilter === 'bearish' ? 'active' : ''
      } sm:text-sm`}
      onClick={() =>
        dispatch({type: 'SET_FILTER', payload: {filterBy: 'bearish', group}})
      }
      content={
        <span className="flex items-center gap-x-2">
          <FontAwesomeIcon icon={faFilter} /> Bearish
        </span>
      }
    />
    <Button
      className={`bullish ${
        activeFilter === 'bullish' ? 'active' : ''
      } sm:text-sm`}
      onClick={() =>
        dispatch({type: 'SET_FILTER', payload: {filterBy: 'bullish', group}})
      }
      content={
        <span className="flex items-center gap-x-2">
          <FontAwesomeIcon icon={faFilter} /> Bullish
        </span>
      }
    />
  </ButtonGroup>
);

export default BearishBullishButtons;
