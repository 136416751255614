import React from 'react';

const Logout = () => {
  localStorage.removeItem('scanner-sso');
  return (
    <div id="page">
      <h1>You have successfully logged out.</h1>
      <p>
        To restart your session, please access from the{' '}
        <a href="https://app.ragingbull.com/member" rel="noreferrer" target="_blank">
          RagingBull portal
        </a>
        .
      </p>
    </div>
  );
};

export default Logout;
