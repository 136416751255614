import React, {useState, useEffect, forwardRef} from 'react';
import DatePicker from 'react-datepicker';
import CustomInputDate from './CustomInputDate';
import Toggle from '../Toggles/Toggle';
import rbLogo from '../../Icons/rb-logo.png';

const moment = require('moment');

const ScannerHeader = ({
  name,
  theme,
  setTheme,
  userData,
}) => {
  const freeAccess = userData?.free_access;
  const headerTitle = name ? 
    <>
      <img className="w-8 h-8" src={rbLogo} alt="Logo" />
      {/* <DarkPoolIcon /> */}
      <h1 className="text-header-color font-bold text-[24px] leading-[28px]">
        {name}
      </h1>
    </>
    : null;


  const ads = freeAccess ? 
      <div id='azk468081' className='pb-4'>{' '}</div>
    : null;

    return(
    <div className="mb-3 mx-auto group-header">
      {/* {ads} */}
      <div className="flex justify-between">
        <div className="flex gap-4 items-center">
          {headerTitle}
        </div>
        <div className="flex gap-7 pr-5">
          <div className="flex items-center">
            <Toggle theme={theme} setTheme={setTheme} />
          </div>
        </div>
      </div>
      <div className='mt-2'>
        <small>Contact support to get the new scanner upgrade 1-800-380-7072 or support@ragingbull.com</small>
      </div>
      <div className="mt-1">
        <small>To change scanner alert subscriptions, pleace go to <u><a href="https://app.ragingbull.com/member/alerts-settings" target="_blank" rel="noreferrer">Member Alert Settings</a></u>.</small>
      </div>
    </div>
    );
 };


export default ScannerHeader;
