/* This example requires Tailwind CSS v2.0+ */
import React, {Fragment} from 'react';
import {Transition, Popover} from '@headlessui/react';
import {ChevronDownIcon} from '@heroicons/react/24/outline';
import BearishBullishButtons from '../Buttons/BearishBullishButtons';

const JoinedBooleanField = ({field, handleValueChange}) => (
  <>
    <legend key={field.type} className="sr-only">
      Filter By
    </legend>
    {field.fields.map(({label, field: fieldKey, option, key}) => (
      <div className="relative flex items-start" key={fieldKey ?? key}>
        <div className="flex items-center h-5">
          <input
            id={fieldKey ?? key}
            name={fieldKey ?? key}
            checked={option}
            type="checkbox"
            onChange={(e) =>
              handleValueChange(e.target.checked, fieldKey ?? key)
            }
            className="focus:ring-transparent h-4 w-4 text-sky-500 border-gray-300 rounded"
          />
        </div>
        <div className="ml-3 text-md flex">
          <label
            htmlFor={fieldKey ?? key}
            className="font-medium text-gray-700 checkbox-label whitespace-nowrap"
          >
            {label}
          </label>
        </div>
      </div>
    ))}
  </>
);

const FilterDropdown = ({
  additionalFields: boolFilters,
  activeFilter,
  showBearBullFilter,
  group,
  dispatch,
  multiScreen,
}) => {
  const handleValueChange = (value, type) => {
    dispatch({
      type: 'UPDATE_FILTER_VALUES',
      payload: {updatedValue: value, key: type, minMax: null, group},
    });
  };
  if (!boolFilters[0]?.fields?.length && !showBearBullFilter) return null;

  return (
    <div className="flex items-center justify-start">
      <Popover as="div" className="relative inline-block text-left">
        <div>
          <Popover.Button
            style={{background: 'rgba(148, 196, 242, 0.1)'}}
            className={`${
              multiScreen ? 'px-2 py-1' : 'px-4 py-2 '
            } filter-label inline-flex justify-center w-full rounded-[50px] border-none  text-[#70769B] border border-gray-300 shadow-sm  bg-white text-md font-medium  hover:bg-gray-50 focus:outline-none focus:ring-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-transparent`}
          >
            Filter
            <ChevronDownIcon
              className="-mr-1 ml-2 h-5 w-5"
              aria-hidden="true"
            />
          </Popover.Button>
        </div>

        <Transition
          key={null}
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Popover.Panel className="w-auto origin-top-right absolute right-0 mt-2 rounded-md shadow-lg bg-white z-50 ring-1 ring-black ring-opacity-5 focus:outline-none">
            {boolFilters.map((field, i) => (
              <fieldset
                className="px-4 py-2 gap-3 flex flex-col"
                key={`${field.type}-${i * 3}`}
              >
                {field.type === 'joined-boolean' || field.type === 'boolean' ? (
                  <JoinedBooleanField
                    field={field}
                    handleValueChange={handleValueChange}
                  />
                ) : null}
              </fieldset>
            ))}
            {showBearBullFilter ? (
              <div className="px-4 py-2 gap-3">
                <BearishBullishButtons
                  group={group}
                  activeFilter={activeFilter}
                  dispatch={dispatch}
                />
              </div>
            ) : null}
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
};

export default FilterDropdown;
